.product-card {
    border: 2px solid #333;
    border-radius: 15px;
    padding: 15px;
    background-color: #ffefcc;
    text-align: center;
  }
  
  .product-image {
    width: 100px;
    height: 100px;
  }
  
  .add-to-cart-btn {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .view-details-link {
    display: block;
    margin-top: 10px;
    color: #00f;
    text-decoration: underline;
  }