.cart-page {
    padding: 20px;
    text-align: center;
  }
  
  .cart-list {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .remove-item-btn {
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .remove-item-btn:hover {
    background-color: #cc0000;
  }