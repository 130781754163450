.header {
    padding-top: 10px;
    background-color: #ffcc00;
    color: #333;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.nav-link {
    display: inline-block;
    padding: 10px;
    margin: 0 10px;
    text-decoration: none;
    color: #333;
}

.active-link {
    background-color: #fff;
}